export const navData = [
  {
    id: 1,
    text: "Home",

    linkTo: "/home",
  },
  {
    id: 2,
    text: "Market",

    linkTo: "/market",
  },
  {
    id: 3,
    text: "Create",

    linkTo: "/select-nft",
  },
 
  {
    id: 4,
    text: "Staking" ,

    linkTo: "/staking",
  },
 
];
