export const sampData = [
  {
    id: 1,
    price: "$15,000",
    name: "TVL",
  },
  {
    id: 2,
    price: "4467",
    name: "Total items in vault",
    },
    {
        id: 3,
        price: "0",
        name: "Total items you staked",
    },
    {
        id: 4,
        price: "SPT 500",
        name: "Your Total Earnings",
    },
];