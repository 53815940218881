import React from "react";
import MyOffersTable from "./MyOfferTable"
const MyOffers = () => {
  return (
    <div className="bg-blue-body w-full h-full">
    <MyOffersTable />
    </div>
  );
};

export default MyOffers;
